import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="Personalized Learning">
      <section className="feature-hero">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-4 margin-top-l">
                  <h1>Personalized Learning</h1>
                  <p className="subtitle">Every student is unique. It’s time that we take a personal approach.</p>
                  <p className="description">Lingco uses adaptive questioning to quickly and accurately determine exactly
                        what a student knows and doesn't know.</p>
               </div>
               <div className="col-xs-12 col-sm-10 col-md-5 col-lg-5 col-lg-offset-1">
                  <div className="feature-img wide"><img src="/img/feature-screen-section.png"
                     alt="feature-screen-section" /></div>
               </div>
            </div>
         </div>
      </section>
      <section className="platform-features">
         <div className="container-fluid">
            <div className="row center-xs middle-xs margin-bottom-xl">
               <div className="col-xs-12 col-sm-10 col-lg-4 col-lg-offset-1">
                  <h3>Dynamic Learning Sessions</h3>
                  <p className="text-larger">Each vocabulary session is tailored to keep the content relevant and suitably
                  challenging for each learner. Lingco takes into account previous knowledge and proficiency to
                  ensure that learners can speed through what they already know and focus on what they need to
                        progress.</p>
               </div>
               <div className="col-xs-12 col-sm-10 col-lg-5">
                  <div className="feature-img wide"><img src="/img/feature-crop-assessment.png"
                     alt="feature-crop-assessment" /></div>
               </div>
            </div>
            <div className="row center-xs middle-xs">
               <div className="col-xs-12 col-sm-10 col-lg-3 col-lg-offset-1 last-xs first-lg">
                  <div className="feature-img"><img src="/img/feature-crop-learning.png" alt="feature-crop-learning" />
                  </div>
               </div>
               <div className="col-xs-12 col-sm-10 col-lg-4 col-lg-offset-1">
                  <h3>Enhanced Feedback</h3>
                  <p className="text-larger">When a student makes a mistake, Lingco analyzes the response and provides
                  feedback on why it's wrong. Whether it's a misplaced accent, an error with gender or number
                  agreement, or an incorrect verb tense, Lingco can guide students in the right direction,
                        eliminating misconceptions and frustrations and promoting learning.</p>
               </div>
               <div className="col-xs-12 col-lg-1"></div>
            </div>
         </div>
      </section>
      <section className="feature-column">
         <div className="container-fluid">
            <div className="row center-xs">
               <div className="col-xs-12 col-sm-10">
                  <div className="row center-xs">
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/content-modules.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Incremental Information</h4>
                              <p>In order to make learning large amounts of information more manageable and less
                              stressful, Lingco breaks down the content into small bite-size study sessions.
                              Rather than attempting to learn an entire vocabulary set at once, students only
                                        focus on a few words at a time.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/paper-pin.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Optimized Reminders</h4>
                              <p>Lingco creates a model of each student's memory and individually assesses the
                              best time for each word or phrase to be practiced. By using spaced repetition,
                              learners can transfer vocabulary from their short term to long term memory
                                        quickly and efficiently.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-md-4">
                        <div className="row text-center">
                           <div className="col-xs-12">
                              <div className="feature-icon"><img src="/img/icons/rewards-certified-badge.svg" /></div>
                           </div>
                           <div className="col-xs-12">
                              <h4>Mastery-Based Learning</h4>
                              <p>Students' final scores on vocabulary sets are never impacted by the number of
                              incorrect answers, but rather by the number of terms that they mastered in the
                              end. This allows students to make mistakes without fear of a bad grade and
                                        encourages more practice for those who need it.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="section-divider"></div>
      </section>
      <section className="feature-more">
         <div className="container-fluid">
            <div className="row center-xs text-center margin-bottom-xl">
               <div className="col-xs-12 col-sm-10">
                  <h5 className="grey">Want to see more features? Check these out</h5>
               </div>
            </div>
            <div className="row center-xs">
               <div className="col-xs-12 col-md-5 margin-bottom-m-md"><Link to="/platform/engaging-content">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Engaging Content</h4>
                           <p>Our platform empowers instructors to create the course that they envision.</p>
                        </div>
                        <div className="feature-more-img content"><img src="/img/feature-more-content.png" /></div>
                     </div>
                  </div>
               </Link></div>
               <div className="col-xs-12 col-md-5"><Link to="/platform/course-management">
                  <div className="card">
                     <div className="row middle-xs">
                        <div className="col-xs-12 col-md-6 margin-top-l">
                           <h4>Course Management</h4>
                           <p>Lingco has all of the tools you need to successfully run a language course.</p>
                        </div>
                        <div className="feature-more-img management"><img src="/img/feature-more-management.svg" />
                        </div>
                     </div>
                  </div>
               </Link></div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
